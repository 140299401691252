import React from 'react';
import PropTypes from 'prop-types';
import Layout from './components/Layout';

const L = ({ children }) => (
  <Layout>
    {children}
  </Layout>
);

L.propTypes = {
  children: PropTypes.node.isRequired,
};

export default L;
