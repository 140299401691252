import React from 'react';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';
import {
  StaticQuery,
  graphql,
} from 'gatsby';

import Link from 'components/Link';
import GlobalStyles from 'styles/GlobalStyles';

const Nav = ({ children }) => (
  <div id="navigation">
    <ul>
      {children}
    </ul>
    <hr />
  </div>
);

const NavItem = props => (
  <Location>
    {({ location }) => (
      <li className={location.pathname === props.to ? 'selected' : ''}>
        <Link to={props.to}>
          {props.children}
        </Link>
      </li>
    )}
  </Location>
);

const Header = () => (
  <div id="header">
    <p id="title"><span className="companyname"><span>Jomag AG</span></span></p>
    <Nav>
      <NavItem to="/">
        Home
      </NavItem>
      <NavItem to="/dienstleistungen">
        Dienstleistungen
      </NavItem>
      <NavItem to="/referenzen">
        Referenzen
      </NavItem>
      <NavItem to="/material">
        Material
      </NavItem>
      <NavItem to="/ueber-uns">
        Über uns
      </NavItem>
      <NavItem to="/kontakt">
        Kontakt
      </NavItem>
    </Nav>
  </div>
);

const Container = ({ children }) => (
  <div id="main-wrapper">
    {children}
  </div>
);

const Footer = () => (
  <div id="footer">
    <hr />
    <p>
Copyright © 2004 -
      {' '}
      {new Date().getFullYear()}
      {' '}
      <Link to="/kontakt">
        Jomag AG
      </Link>
    </p>
    <p className="vcard adr">
      <span className="fn" style={{ display: 'none' }}>Erich Mathys</span>
      <span className="street-address">Stutzackerweg 2</span>
,
      <span className="postal-code">3110</span>
      {' '}
      <span className="locality">Münsingen</span>
      <span style={{ display: 'none' }}>
,
        <span className="region">Bern</span>
      </span>
      <span style={{ display: 'none' }}>
,
        <span className="country-name">Switzerland</span>
      </span>
    </p>
    <p>
      Tel:
      <span className="tel">+41 31 722 0706</span>
    </p>
  </div>
);

const Layout = ({ children }) => (
  <GlobalStyles>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <Container>
          <Header />
          <div id="content-wrapper">
            <div id="content">
              {children}
            </div>
          </div>
          <Footer />
        </Container>
      )}
    />
  </GlobalStyles>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
