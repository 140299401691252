module.exports = [{
      plugin: require('/home/max/projects/jomagag/gatsby-site/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/max/projects/jomagag/gatsby-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-136005603-1","anonymize":true,"respectDNT":true},
    },{
      plugin: require('/home/max/projects/jomagag/gatsby-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
