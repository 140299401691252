import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

import headerImage from './images/header.jpg';
import h1bgImage from './images/h1bg.gif';
import navbgImage from './images/navbg.png';

// eslint-disable-next-line
const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
  }

  body {
    background-color: #fafafa;
    color: #333;
    font-family: Verdana, Helvetica, Arial, sans-serif;
    font-size: 11px;
    line-height: 1.4em;
  }

  h1, h2, h3, h4, h5, h6, ul, ol, form, p {
    margin: 0 0 1em 0;
  }

  input.std, textarea {
    border: 0.0909em solid #bbb;
    font-size: 1em;
  }

  button {
    background-color: #555;
    border: 0.0909em solid #333;
    color: #fff;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
  }

  .link-ish,
  a:link,
  a:visited {
    cursor: pointer;
    text-decoration: none;
    color: #3c60b3;
    outline: none;
  }

  .link-ish:hover,
  .link-ish:active,
  .link-ish.active,
  a:hover,
  a:active {
    background-color: #3c60b3;
    color: #fff;
  }

  #header hr {
    display: none;
  }

  #main-wrapper {
    margin: 15px auto 0 auto;
    padding: 0 0 15px 0;
    width: 42em;
  }

  #title {
    margin: 0;
    padding: 0.45em;
    width: 40.91em;
    border: 0.0909em solid #b2b2b2;
  }

  #title span {
    display: block;
    height: 130px;
    background-image: url(${headerImage});
    background-color: #fff;
  }

  #title span span {
    display: none;
  }

  #content-wrapper {
    padding: 10px 5px 5px 5px;
    background-color: #fff;
    border: 0.0909em solid #b2b2b2;
    border-top: none;

  }

  #navigation ul {
    position: relative;
    z-index: 10;
    margin: 1em 0 0 0;
    padding: 0 0 0 0.2em;
    height: 1.91em;
    background-image: url(${navbgImage});
    background-position: bottom left;
    background-repeat: repeat-x;
  }

  #navigation ul li {
    display: block;
    float: left;
    padding-right: 0.1818em;
    list-style-type: none;
  }

  #navigation ul li a:link, #navigation ul li a:visited {
    display: block;
    float: left;
    padding: 0 0.3em 0 0.3em;
    height: 1.73em;
    line-height: 1.73em;
    text-decoration: none;
    font-weight: bold;
    font-size: 1em;
    border: 0.0909em solid #ccc;
    border-bottom: 0.0909em solid #b2b2b2;
    color: #666;
    background-color: #e2e2e2;
  }

  #navigation ul li a:hover, #navigation ul li a:active {
    background-color: #d6d6d6;
    border: 0.0909em solid #bfbfbf;
    border-bottom: 0.0909em solid #b2b2b2;
  }

  #navigation ul li.selected a {
    background-color: #fff;
    border: 0.0909em solid #b2b2b2;
    border-bottom: 0.0909em solid #fff;
  }

  #content {
    margin-top: -2em;
  }

  #content h1 {
    margin-top: 2em;
    padding: 0 0 0.3em 0;
    color: #4a66a5;
    font-size: 1.2em;
    background-image: url(${h1bgImage});
    background-position: bottom left;
    background-repeat: repeat-x;
  }

  #content ul {
    padding: 0 0 0 2em;
  }

  #content ul li {
    list-style-type: square;
  }

  #content .postaladdress {
    margin: 2em;
  }

  #footer {
    padding: 1em 0 1em 0;
    text-align: center;
  }

  #footer hr {
    display: none;
  }

  #footer p {
    margin-bottom: 0.2em;
    color: #8094c0;
    font-size: 0.9em;
  }

  .service-body {
    display: flex;
  }

  .service.left .service-body {
    flex-direction: row-reverse;
  }

  .service-body .options {
    padding-top: 3em !important;
  }

  .service-body .illustration {
    flex-shrink: 0;
    box-sizing: content-box;
    width: 195px;
    height: 195px;
    overflow: hidden;
    border: 1px solid #b2b2b2;
  }

  .service-body .illustration img {
    display: block;
  }

  .service-body .illustration div.slide {
    transform: translateY(0);
    transition: transform 0.2s ease-out;
  }


form p {
  height: 1.8em;
}

form p.error {
  height: 1em;
  color: red;
  font-weight: bold;
}

form p.freetext {
  height: 14em;
}

form p.buttons {
  padding: 1em 0 0 9em;
}

label {
  display: block;
  float: left;
  width: 9em;
}

input.std {
  float: left;
  width: 22em;
}

textarea {
  width: 30em;
  height: 14em;
}

@media (max-width: 500px) {
  body {
    font-size: 14px;
  }

  #main-wrapper {
    box-sizing: border-box;
    width: 100%;
    padding: 0 10px;
  }

  #title {
    box-sizing: border-box;
    width: 100%;
  }

  #title span {
    background-position: right;
  }

  #navigation ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    height: auto;
    padding-bottom: 10px;
  }

  #navigation ul li {
    float: none;
    padding-right: 0.4em;
    padding-bottom: 0.4em;
  }

  #navigation ul li a:link,
  #navigation ul li a:visited {
    height: auto;
    line-height: 1;
    display: flex;
    align-items: center;
    padding: 0.3em 0.6em;
  }

  #navigation ul li a:hover,
   #navigation ul li a:active {
    background-color: #d6d6d6;
  }

  #navigation ul li.selected a {
    background-color: #fff;
    border: 0.0909em solid #b2b2b2;
  }

  .service {
    padding-bottom: 1em;
  }

  .service-body,
  .service.left .service-body {
    flex-direction: column-reverse;
    align-items: center;
  }

  .service-body .options {
    box-sizing: border-box;
    width: 100%;
    padding-left: 2em !important;
    padding-top: 1em !important;
    padding-bottom: 1em !important;
  }

  form label {
    margin-top: 0.6em;
  }

  form input.std {
    width: 100%;
  }

  form textarea {
    width: 100%;
    height: 8em;
  }

  form p.freetext {
    height: 12em;
  }

  form p.buttons {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
  }
}
`;

const GlobalStyles = ({ children }) => (
  <>
    <GlobalStyle />
    {children}
  </>
);

GlobalStyles.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalStyles;
