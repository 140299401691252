// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-index-jsx": () => import("/home/max/projects/jomagag/gatsby-site/src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-dienstleistungen-index-jsx": () => import("/home/max/projects/jomagag/gatsby-site/src/pages/dienstleistungen/index.jsx" /* webpackChunkName: "component---src-pages-dienstleistungen-index-jsx" */),
  "component---src-pages-home-index-jsx": () => import("/home/max/projects/jomagag/gatsby-site/src/pages/home/index.jsx" /* webpackChunkName: "component---src-pages-home-index-jsx" */),
  "component---src-pages-index-jsx": () => import("/home/max/projects/jomagag/gatsby-site/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-index-jsx": () => import("/home/max/projects/jomagag/gatsby-site/src/pages/kontakt/index.jsx" /* webpackChunkName: "component---src-pages-kontakt-index-jsx" */),
  "component---src-pages-material-index-jsx": () => import("/home/max/projects/jomagag/gatsby-site/src/pages/material/index.jsx" /* webpackChunkName: "component---src-pages-material-index-jsx" */),
  "component---src-pages-referenzen-index-jsx": () => import("/home/max/projects/jomagag/gatsby-site/src/pages/referenzen/index.jsx" /* webpackChunkName: "component---src-pages-referenzen-index-jsx" */),
  "component---src-pages-ueber-uns-index-jsx": () => import("/home/max/projects/jomagag/gatsby-site/src/pages/ueber-uns/index.jsx" /* webpackChunkName: "component---src-pages-ueber-uns-index-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/max/projects/jomagag/gatsby-site/.cache/data.json")

